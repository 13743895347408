<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="displayedDate"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="displayedDate"
      no-title
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="cancelAction"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="okAction"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: false,
      default: new Date().toISOString().substr(0, 10),
    },
  },
  data() {
    return {
      menu: false,
      previousDate: this.date,
      displayedDate: this.date,
    }
  },
  methods: {
    okAction() {
      this.previousDate = this.displayedDate
      this.$emit('date-changed', this.displayedDate)
      this.menu = false
    },
    cancelAction() {
      this.displayedDate = this.previousDate
      this.menu = false
    },
  },
}
</script>
